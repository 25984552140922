<template src="./addfxtransaction.html">  
</template>
<style scoped src="./addfxtransaction.css">
</style>
<script>
import FxConstants from '@/constants/FxConstants'
import Loading from 'vue-loading-overlay'
import { mapActions, mapGetters } from 'vuex'
import FxService from '@/services/FXServices'
import { required, numeric, requiredIf } from 'vuelidate/lib/validators'
import AppConstants from '@/constants/AppConstants.vue'
import modal from '@/components/modal/notification/modal'

export default {
    data() {
        return {    
            fxTransaction : {
                provider : '',
                sourceFxAcc : '',
                sourceAmt : '',
                destFxAcc : '',
                buyRate : '' ,
                fxTransactionType : '',
                fxTransactionStatus : ''
            },           
            providerList : FxConstants.fxProvider,
            sourceFxAccList : [],
            destFxAccList : [],
            loaderVisible : false,
            disableFxBuyRate : true,
            disableSourceFxAccount : false,
            fxAccountList : null,
            errorMessage : AppConstants.formsMessages,
            alertObject: {
                errorCode: null,
                errorDescription: null,
                errorDetails: []
            }
        }
    },
    methods : {
        ...mapActions(['fetchFxAccount']),
        onAddFxTransation () {
            this.$v.fxTransaction.$touch()
            if(this.$v.fxTransaction.$invalid) {
                return
            }
            if(this.fxTransactionType === '1') {
                this.sourceFxAcc = ''
            } else if (this.fxTransactionType !== '2') {
                this.buyRate = ''
            }
            this.loaderVisible = true
            FxService.methods.updateManualFxTransactionRecored({
                'FXTransactionType'   : this.fxTransaction.fxTransactionType,
                'FXTransactionStatus' : this.fxTransaction.fxTransactionStatus,
                'SourceFXAccount'     : this.fxTransaction.sourceFxAcc,
                'SourceAmount'        : this.fxTransaction.sourceAmt,
                'DestinationFXAccount': this.fxTransaction.destFxAcc,
                'FXBuyRate'           : this.fxTransaction.buyRate
            })
            .then(res=> {
                this.showAlert(1, 'Added Successfully')
                this.loaderVisible = false
            })
            .catch(err => {    
                this.loaderVisible = false
                this.alertObject = err
                this.$bvModal.show(this.$refs.childModal.id)
            })
        },
        onClear () {    
            this.fxTransaction.fxTransactionType = '',
            this.fxTransaction.fxTransactionStatus ='',
            this.fxTransaction.sourceFxAcc = '',
            this.fxTransaction.sourceAmt = '',
            this.fxTransaction.destFxAcc ='',
            this.fxTransaction.buyRate = ''
            this.fxTransaction.provider = ''
            this.disableFxBuyRate = false
            this.$v.$reset()        
        },
        onFxTransationTypeChange($event) {
            this.sourceFxAccList = [{value: '' , text : '-- Select SourceFxAccount'}]    
            this. destFxAccList = [{value: '' , text : '-- Select DestFxAccount'}]
            this.fxTransaction.sourceFxAcc = ''
            this.fxTransaction.destFxAcc =''
            this.fxTransaction.buyRate = ''
            switch($event.target.value) {
                case '':
                    this.disableFxBuyRate =  true
                    this.disableSourceFxAccount = false
                break  
                case '1':
                    this.disableFxBuyRate =  true
                    this.disableSourceFxAccount = true               
                break
                case '2':
                    this.disableFxBuyRate =  false
                    this.disableSourceFxAccount = false
                break
                case '5':
                    this.disableFxBuyRate =  true
                    this.disableSourceFxAccount = false
                break                      
            }
        },
        UpdateSourceFxAccountList() {
            this.sourceFxAccList = [{value: '' , text : '-- Select SourceFxAccount'}]   
            this.fxAccountList.map(s=> {
                this.sourceFxAccList.push({
                    text : s.Currency,
                    value: s.FXAccountId
                })
            })
            this.fxTransaction.sourceFxAcc = ''        
        },
        UpdateDestinationFXAccount(sourceFxAcc = '') {
            this. destFxAccList = [{value: '' , text : '-- Select DestFxAccount'}]          
            this.fxAccountList.map(s=> {    
                if ( s.FXAccountId !== sourceFxAcc) {
                     this.destFxAccList.push({                    
                        text : s.Currency,
                        value: s.FXAccountId
                    })
                }               
            })
            this.fxTransaction.destFxAcc =''
        },
        onProviderChange(event) {
            let provider = this.providerList.find(s=> s.value ===  event)
            this.fxAccountList = this.getFxAccountDetails.filter(s=> s.FXProviderName === provider.text)
            this.destFxAccList = [{value: '' , text : '-- Select DestFxAccount'}]
            this.sourceFxAccList = [{value: '' , text : '-- Select SourceFxAccount'}]
            if(this.disableSourceFxAccount){
                this.UpdateDestinationFXAccount()
            } else {
                this.UpdateSourceFxAccountList()
            }
        },
        onSourceFxAccountChange (event) {
            this.UpdateDestinationFXAccount(event)
        },
        showAlert(code, description) {
            this.alertObject.errorCode = code
            this.alertObject.errorDescription = description
            this.alertObject.errorDetails = []
            this.$bvModal.show(this.$refs.childModal.id)
        }
    },
    validations: {
        fxTransaction: {
            provider : { required },
            sourceFxAcc : { required: requiredIf(function () { return !this.disableSourceFxAccount }) },
            sourceAmt : { required },
            destFxAcc : { required },
            buyRate : { required: requiredIf(function () { return !this.disableFxBuyRate }) },
            fxTransactionType : { required },
            fxTransactionStatus : { required }
        }
    },
    computed : {
        ...mapGetters(['getFxAccountDetails'])
    },
    components : {
        Loading, modal
    },
    async created () {
        this.sourceFxAccList = [{value: '' , text : '-- Select SourceFxAccount'}]    
        this. destFxAccList = [{value: '' , text : '-- Select DestFxAccount'}]
        if (!this.getFxAccountDetails) {
            this.loaderVisible = true
            await this.fetchFxAccount()
            this.loaderVisible = false
        }
        this.providerList.forEach(item => {
            if(item.value == '')
                item.text = '--Select Provider--'
        })
    } 
}
</script>